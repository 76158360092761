<template>
  <div class="c-app flex-row align-items-center login-layout" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <div class="header">
      <CContainer>
        <div class="header-items">
          <img :src="require('@/assets/images/logo.png')" alt="" class="logo">
          <a @click="redirectToSignUp" class="sign-up">Sign Up</a>
        </div>
      </CContainer>
    </div>
    <CContainer>
      <CToaster :autohide="3000">
        <template v-for="info in infoList">
          <CToast :key="info.message" :show="true" :header="info.header" :color="info.color">
            {{ info.message }}.
          </CToast>
        </template>
      </CToaster>

      <div class="login-card-layout">
        <div class="login-text">
          <h1 class="login-text-heading">Welcome Back</h1>
          <p class="login-text-paragraph">
            Login now and
            <br>
            explore our amazing features
          </p>
        </div>
        <div class="login-card-body-layout">
          <CCardBody class="login-card-body">
            <CForm class="mb-2 text-center">
              <h1 class="login-card-header">Login</h1>
              <div class="form-group-login">
                <input type="text" id="username" autocomplete="username email" class="form-control floating-label-input"
                  v-model="loginObj.email" @focus="isEmailInputFocused = true" @blur="isEmailInputFocused = false" />
                <label for="username" class="floating-label"
                  :class="{ 'floating': isEmailInputFocused || loginObj.email }">
                  Email Address
                </label>
              </div>
              <div class="form-group-login">
                <input :type="passwordType" id="password" autocomplete="curent-password"
                  class="form-control floating-label-input" v-model="loginObj.password"
                  @focus="isPasswordInputFocused = true" @blur="isPasswordInputFocused = false" />
                <label for="password" class="floating-label"
                  :class="{ 'floating': isPasswordInputFocused || loginObj.password }">
                  Password
                </label>
                <span @click="togglePasswordVisibility" class="eye-icon">
                  <img :src="eyeIconSrc" alt="" class="column-icon">
                </span>
              </div>
              <CRow class="login-card-util">
                <CCol col="6" class="text-left">
                  <CInputCheckbox class="remember-me" id="exampleCheckbox" label="Remember Me"
                    v-model="loginObj.rememberMe" />
                </CCol>
                <CCol col="6" class="text-right">
                  <a href="#" class="forgot-password">Forgot Password</a>
                </CCol>
              </CRow>
              <CRow>
                <CCol col="12" class="text-center">
                  <CButton color="primary" class="px-4 login-button" @click.prevent="login">Login</CButton>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
          <p class="login-info">Never share any of your login details with anyone.</p>
        </div>
      </div>
    </CContainer>
  </div>
</template>

<script>
let apiUrl = process.env.VUE_APP_API_URL;
export default {
  name: "Login",
  data: () => {
    return {
      passwordType: "password",
      infoList: [],
      logoUrl: "img/logo.png",
      loginObj: {
        email: "",
        password: "",
        rememberMe: "",
        // authenticated: true,
      },
      user: null,
      inputValue: '',
      isEmailInputFocused: false,
      isPasswordInputFocused: false,
      userId: null,
      phoneNumber: null, 
      temp: null,
      showPassword: false,
      eyeIconSrc: require('@/assets/images/icons/view.png')
    };
  },
  computed: {
    inputId() {
      return 'floating-label-input-' + this._uid
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
      if (this.passwordType === 'password') {
        this.passwordType = 'text'
        this.eyeIconSrc = require('@/assets/images/icons/hide.png')
      } else {
        this.passwordType = 'password'
        this.eyeIconSrc = require('@/assets/images/icons/view.png')
      }
    },
    gotToAdmin() {
      var self = this;
      self.$router.push({ path: "/pages/tenantLogin" });
    },
    fetchUser() {
      let email = this.loginObj.email;
      let url = apiUrl + '/users?email=' + email;
      return api.call('get', url)
        .then((response) => {
          this.user = response.data.users;
          console.log('user: ', this.user);
          this.userId = this.user[0]._id;
          this.userPhoneNumber = this.user[0].phoneNumber;
          console.log('userId', this.userId, 'userPhoneNumber', this.userPhoneNumber)
          this.temp = {
            phoneNumber: this.userPhoneNumber, 
            id: this.userId
          }
          window.localStorage.setItem('query', JSON.stringify(this.temp));
        })
    },
    login() {
      console.log('it works!');
      console.log('login obj', this.loginObj)
      var self = this;
      let data = {
        email: self.loginObj.email,
        password: self.loginObj.password,
      };
      console.log('yes')
      auth.doLogin(data).then((response) => {
        console.log(response);
        auth.recordLogin(response.tokens.access, response.user, response.tokens.refresh);
        self.$router.push({ path: "/folder" });
        // self.$router.push({ path: "/" });
      })
      .catch(({data}) => {
        if(data.message === 'Your account are not verified yet') {
          this.fetchUser()
          // self.$router.push({ path: "verify-account" });
          this.twilioVerifyOTP()
          this.redirectToVerifyAccount()
        }
        self.toast(
          "Error",
          data.message,
          "danger"
        );
      });
    },
    twilioVerifyOTP() {
      const url = `${apiUrl}/twiliosms/`;
      let payload = {
        email: this.loginObj.email
      }
      window.localStorage.setItem('query', JSON.stringify(payload));
      return api.call('post', url, payload).then((res) => {
        if (res.status === 201) {
          console.log(res)
          this.redirectToVerifyAccount()
        }
      }).catch(({data}) => {
        this.toast(
          "Error",
          data.message,
          "danger"
        );
      })
    },
    redirectToVerifyAccount() {
      this.$router.push({ path: "verify-account" });
    },
    toast(header, message, color) {
      var self = this;
      self.infoList.push({
        header: header,
        message: message,
        color: color,
      });
    },
    redirectToSignUp() {
      this.$router.push({ path: "register" });
    }
  },
};
</script>

<style>
.logo {
  width: 100%;
  background: #fff;
  max-width: 115px;
}
.form-group-login {
  position: relative;
  margin-bottom: 24px;
}

.floating-label-input {
  border: none;
  border-bottom: 1px solid #D9D9D9;
  padding: 0.5rem;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #262626;
  border-radius: 0;
}

.floating-label-input:focus {
  outline: none;
  border-color: #0A1439;
  box-shadow: none;
  border-radius: 0;
}

.floating-label {
  position: absolute;
  top: 0;
  left: 8px;
  pointer-events: none;
  font-size: 16px;
  transition: all 0.2s ease;
}

.floating {
  transform: translateY(-100%);
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #0A1439;
}

.login-layout {
  background: #0A1439;
  position: relative;
}

.login-layout:after {
  background: #fff;
  content: '';
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 33%;
}

.login-text {
  flex-grow: 1;
}

.login-card-header {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  margin-bottom: 40px;
}

.login-card-layout {
  display: flex;
  align-items: center;
}

.login-card-body-layout {
  max-width: 475px;
  z-index: 2;
  width: 100%;
}

.login-card-body {
  background: #fff;
  width: 100%;
  flex: 0 0 auto;
  border-radius: 8px;
  padding: 80px 40px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.login-text-heading {
  font-size: 48px;
  font-weight: 700;
  line-height: 55px;
  color: #fff;

}

.login-text-paragraph {
  font-size: 18px;
  line-height: 25px;
  color: #fff;
  padding-top: 16px;
}

.login-card-util {
  padding-top: 24px;
}

.forgot-password {
  font-size: 16px;
  line-height: 19px;
  color: #8C8C8C;
  text-decoration: underline;
  transition: 0.3s ease;
}

.remember-me {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #8C8C8C;
}

.login-button {
  width: 100%;
  display: block;
  margin-top: 40px;
  padding: 18px;
  max-width: 300px;
  background: #2566EB;
  border-radius: 35px;
  font-size: 16px;
  font-weight: 700;
  margin-inline: auto;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 32px;
  color: #fff;
}

.header-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.eye-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 20px;
  color: #888;
  width: 20px;
}

.eye-icon.active {
  color: #007bff; /* Change the color to indicate password visibility */
}

</style>
